"use client"
import { CombinedError } from "@urql/next"
import { useCallback, useContext } from "react"
import { ApiProductPermissionErrorMessage } from "../utils"
import { PermissionContext } from "./PermissionProvider"

export const usePermission = () => {
  const { hasPermission, allowRole } = useContext(PermissionContext)
  const hasAccess = useCallback((askFor?: string[], blackList?: string[]) => {
    return [
      hasPermission(askFor, blackList),
      allowRole(askFor, blackList),
    ].some(Boolean)
  }, [])

  const hasApiAccess = useCallback((_error?: CombinedError) => {
    if (!_error) return true
    return !_error?.graphQLErrors?.some((err) =>
      err.message.includes(ApiProductPermissionErrorMessage),
    )
  }, [])
  return { hasAccess, hasPermission, allowRole, hasApiAccess }
}
